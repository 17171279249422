@import '../../shared/settings';

.big-header {
    position: relative;
    margin: 0 0 54px;
    border-radius: 10px;
    height: 556px;
   
    figure {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        border-radius: 10px;

        svg {
            display: none;
        }
    }

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.4;
        z-index: 1;
        border-radius: 10px;
        background: linear-gradient(
            180deg,
            rgba($gradient, 0) 2%,
            $gradient 69%,
            $gradient 100%
        );
        content: ' ';
    }

    .big-header-content {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 2;
        border-radius: 10px;
        height: 100%;
        text-align: center;
        color: white;
    }

    h1,
    h4,
    p {
        text-shadow: 0 2px 4px rgba($dark, 0.25);
    }

    h1 {
        margin: 0 0 16px;
        overflow: inherit;
        text-overflow: inherit;
        line-height: 56px;
        white-space: normal;
        font-size: 48px;
        font-weight: bold;
    }

    p {
        margin: 0 auto;
        max-width: 660px;
        line-height: 34px;
        font-size: 24px;
    }

    .titles {
        position: absolute;
        top: 200px;

        p {
            width: 660px;
        }
    }

    .mobile-links {
        display: none;
    }

    .desktop-links,
    .mobile-links {
        position: absolute;
        bottom: -22px;

        h4 {
            margin: 0 0 14px;
            text-transform: uppercase;
            line-height: 26px;
            font-size: 16px;
        }

        ul {
            display: inline-flex;
            flex-direction: row;
            margin: 0;
            border: 1px solid $light-grey;
            border-radius: 22px;
            background-color: white;
            padding: 0 12px;
            list-style: none;

            li {
                transition: color $slow;

                &:first-child {
                    border-radius: 4px 0 0 4px;
                }

                &:last-child {
                    border-radius: 0 4px 4px 0;
                }

                a {
                    display: block;
                    transition: color $fast;
                    padding: 11px 12px 12px;
                    text-decoration: none;
                    color: $dark-grey;
                    font-size: 16px;
                    font-weight: var(--medium);
                }

                &:hover {
                    a {
                        transition: color $fast;
                        color: $brand;
                    }
                }
            }
        }
    }

    @include respond-to(mobile) {
        margin: 0;
        border-radius: 0;
        padding: 0;
        max-height: 70vh;

        &::after {
            border-radius: 0;
        }

        figure {
            border-radius: 0;
        }

        .big-header-content {
            display: block;
            border-radius: 0;
        }

        .titles {
            position: absolute;
            top: 92px;
            transform: translateY(-50%);
            text-align: center;

            h1 {
                margin: 0;
                line-height: 38px;
                font-size: 32px;
            }

            p {
                margin: 16px 0 0;
                padding: 0 20px;
                width: calc(100% - 40px);
                max-width: 100%;
                line-height: 26px;
                font-size: 18px;
            }
        }

        .desktop-links {
            display: none;
        }

        .mobile-links {
            display: block;
        }

        .mobile-links {
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);

            h4 {
                margin-bottom: 6px;
                line-height: 24px;
                font-size: 14px;
            }

            ul {
                display: grid;
                padding: 0 15px;
                grid-template-columns: auto auto auto;
                width: 100%;
                box-sizing: border-box;

                li {
                    a {
                        padding: 7px 10px;
                        line-height: 24px;
                        font-size: 14px;
                    }
                }
            }
        }

        & + .title {
            margin-top: 56px;
        }
    }

    @include respond-to(mobile-portrait) {
        .titles {
            top: 45%;

            h1 {
                margin: 0;
                padding: 0 20px;
                line-height: 44px;
                font-size: 38px;
            }
        }
    }
}
