@import '../../../../../scss/mixins/respond.scss';

.header {
    --accent: #FFE200;
    border-radius: 0;
    height: 540px;

    &:after {
        display: none;
    }

    h1, p {
        color: black;
        text-shadow: none;
    }
 
    background: repeating-linear-gradient(180deg,black, black 30px, var(--accent) 30px, var(--accent) 60px);

    @include respond-to(mobile) {
        max-height: unset;
        background: repeating-linear-gradient(0deg,black, black 30px, var(--accent) 30px, var(--accent) 60px);
    }
}

.content {
    padding: 60px 40px;
    box-sizing: border-box;
}

.slogan {
    position: relative;
    background: white;
    width: 100%;
    height: 30px * 14;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    h1, p {
        font-family: 'Poppins', sans-serif !important;
        font-size: 48px;
        font-weight: 700;
        line-height: 36px;
        position: relative;
    }

    h1 {
        margin-top: 46px;
    }

    > svg {
        position: absolute;
        height: auto;
        width: 847px;
        left: 50%;
        transform: translate(-62%, -6%);
    }

    @include respond-to(mobile) {
        > svg {
            left: auto;
            right: 6vw;
            bottom: 36px;
            transform: none;
        }
    }
}
